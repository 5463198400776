import React, { useEffect, useState } from "react";

import {
  Button,
  Flex,
  Heading,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";

import { Role } from "types";

type Props = {
  isSharedWithTeam: boolean;
  handleChangeSwitch: (option: string) => void;
  isLoading: boolean;
};

const ROLES_LIST = [
  Role.owner,
  Role.maintainer,
  Role.contributor,
  Role.readOnly,
];

export const ShareWithTeam: React.FC<Props> = ({
  isSharedWithTeam,
  handleChangeSwitch,
  isLoading,
}) => {
  const [select, setSelect] = useState<string>("readonly");
  const [isChecked, setIsChecked] = useState<boolean>(isSharedWithTeam);

  useEffect(() => {
    setIsChecked(isSharedWithTeam);
  }, [isSharedWithTeam]);

  const handleSwitchChange = (): void => {
    setIsChecked(!isChecked);
    handleChangeSwitch(select);
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelect(event.target.value);
  };

  return (
    <Stack pl={4}>
      <Heading fontSize="24px" fontWeight="extrabold" marginBottom={"20px"}>
        Share With Team Members
      </Heading>

      <Flex align="center" direction="row" justifyContent={"space-between"}>
        <Flex height="36px" alignItems={"center"}>
          <Switch
            size="md"
            id="email-alerts"
            isChecked={isChecked}
            onChange={handleSwitchChange}
            height="20px"
          />

          <Text
            fontSize="2xl"
            fontWeight="bold"
            ml={4}
            color={isChecked ? "black" : "gray.400"}
          >
            Anyone in the team
          </Text>

          {isLoading && (
            <Button isDisabled isLoading ml={2} background={"none"} />
          )}
        </Flex>

        <Select
          variant="unstyled"
          size={"lg"}
          w={"fit-content"}
          fontSize="md"
          value={select}
          onChange={handleChangeSelect}
          paddingInlineEnd={0}
          textAlign={"center"}
        >
          {ROLES_LIST.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </Select>
      </Flex>
    </Stack>
  );
};
