import { useEffect, useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import { Select, useToast } from "@chakra-ui/react";

import { shareProject } from "shared/api/client";

import { Role, SharedProjectUser, ShareProjectPayload } from "types";

type Props = {
  user: SharedProjectUser;
  isTeamUser: boolean | undefined;
  isHighToManitainer: boolean;
  options: string[];
  isAccessCurrentOwner: boolean;
};

export const RoleSelect: React.FC<Props> = ({
  user,
  isTeamUser,
  isHighToManitainer,
  options,
  isAccessCurrentOwner,
}) => {
  const [select, setSelect] = useState<string>(user.role);
  const toast = useToast();

  const queryClient = useQueryClient();

  const updateUserRoleMutation = useMutation(
    async (payload: ShareProjectPayload) => {
      return shareProject(payload);
    },
    {
      onSuccess: (_, payload) => {
        toast({
          title: "Role updated",
          description: t`Successfully updated the role `,
          position: "bottom",
          status: "success",
          isClosable: true,
        });

        setSelect(payload?.role);
        queryClient.invalidateQueries(["project", user.projectId]);
      },
      onError: (err, payload) => {
        console.error(err);
        toast({
          title: "Failed to update role",
          description: t`Could not update the role`,
          isClosable: true,
          duration: 10000,
          position: "bottom",
          status: "error",
        });
      },
    }
  );

  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const role: Role = event.target.value as Role;
    updateUserRoleMutation.mutate({ ...user, role });
  };

  useEffect(() => {
    setSelect(user.role);
  }, [user.role]);

  return (
    <Select
      variant="unstyled"
      size={"lg"}
      w={"fit-content"}
      value={select}
      onChange={handleChangeSelect}
      p={0}
      textAlign={"left"}
      disabled={!isTeamUser || isHighToManitainer || isAccessCurrentOwner}
      cursor={
        isTeamUser && !isHighToManitainer && !isAccessCurrentOwner
          ? "pointer"
          : "no-drop"
      }
    >
      {options.map((role) => (
        <option key={role} value={role}>
          {role}
        </option>
      ))}
    </Select>
  );
};
