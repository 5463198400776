import { useMutation, useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import { Box, IconButton, useDisclosure, useToast } from "@chakra-ui/react";

import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";

import { unshareProject } from "shared/api/client";

import { ShareProjectPayload } from "types";

import { UnshareUserDialog } from "./unshare-dialog";

type Props = {
  projectId: string;
  userId: string;
  email: string;
};

export const UnshareUser: React.FC<Props> = ({ projectId, userId, email }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const unshareProjectMutation = useMutation(
    async (payload: Pick<ShareProjectPayload, "projectId" | "userId">) => {
      return unshareProject(payload);
    },
    {
      onSuccess: () => {
        toast({
          title: "Project unshared",
          description: t`Successfully unshared with ${email}`,
          position: "bottom",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries(["project", projectId]);
        onClose();
      },
      onError: (err, payload) => {
        console.error(err);
        toast({
          title: "Failed to unshare project",
          description: t`Could not unshare with ${email}`,
          isClosable: true,
          duration: 10000,
          position: "bottom",
          status: "error",
        });
        onClose();
      },
    }
  );

  const handleConfirm = () => {
    unshareProjectMutation.mutate({ projectId, userId });
  };

  return (
    <>
      <Box onClick={onOpen}>
        <IconButton
          aria-label="delete"
          icon={<FiTrash2 />}
          size="xs"
          border="1px solid"
          color="red.500"
        />
      </Box>

      <UnshareUserDialog
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          handleConfirm();
        }}
        title={t`Unshare project?`}
        description={t`Are you sure you want to unshare ${email}?`}
        cancelText={t`Cancel`}
        confirmText={t`Unshare`}
      />
    </>
  );
};
