import { useCallback, useMemo } from "react";

import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { t, Trans } from "@lingui/macro";

import { Box, CircularProgress, useToast } from "@chakra-ui/react";

import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";

import NotIdealState from "components/not-ideal-state";

import { fetchProjectById, fetchTeamMembers } from "shared/api/client";
import { shareProject, unshareProject } from "shared/api/client";
import { useUserInfo } from "shared/api/client/hook";

import {
  ExtendedSharedProject,
  Project,
  Role,
  SharedProjectUser,
  ShareProjectPayload,
  TeamMember,
} from "types";

import { ShareUserExternaly } from "./share-externaly";
import { ShareWithTeam } from "./share-with-team";
import { SharedUsersTable } from "./table";

type Props = {
  projectId: string;
};

export const ModalBodyContent: React.FC<Props> = ({ projectId }) => {
  const { data: userStatusData, isError: userIsError } = useUserInfo();
  const currentUserEmail = userStatusData?.email;

  const team = userStatusData?.teams[0];
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: teamData, isLoading: isTeamLoading } = useQuery<TeamMember[]>({
    queryKey: ["teamMembers"],
    queryFn: async () => {
      if (team && team.teamId) {
        const teamMembers = await fetchTeamMembers(team.teamId);
        return teamMembers;
      }
      return [];
    },
    enabled: !userIsError,
  });

  const fetchProject = useCallback(
    () => fetchProjectById(projectId),
    [projectId]
  );

  const {
    data: project,
    isError,
    isLoading,
  } = useQuery<Project, AxiosError>(["project", projectId], fetchProject, {
    cacheTime: 0,
  });

  const teamWitoutOwner = useMemo(() => {
    if (teamData)
      return teamData.filter((user) => user.email !== userStatusData?.email);
    return [];
  }, [teamData, userStatusData]);

  const shareProjectWithTeamMembers = useCallback(
    async (payload: Omit<ShareProjectPayload, "email">) => {
      return await Promise.all(
        teamWitoutOwner.map(async (user) => {
          try {
            const response = await shareProject({
              ...payload,
              email: user?.email,
            });
            return response;
          } catch (err) {
            console.error(err);
          }
        })
      );
    },
    [teamWitoutOwner, shareProject]
  );

  const unshareProjectWithTeamMembers = useCallback(async () => {
    return await Promise.all(
      teamWitoutOwner.map(async ({ userId }) => {
        try {
          const response = await unshareProject({
            projectId,
            userId,
          });
          return response;
        } catch (err) {
          console.error(err);
        }
      })
    );
  }, [teamWitoutOwner, projectId, unshareProject]);

  const shareProjectMutation = useMutation(shareProjectWithTeamMembers, {
    onSuccess: () => {
      queryClient.invalidateQueries(["project", projectId]);

      toast({
        title: "Project shared",
        description: t`Successfully shared with team`,
        position: "bottom",
        status: "success",
        isClosable: true,
      });
    },
    onError: (err, payload) => {
      console.error(err);
      toast({
        title: "Failed to share project",
        description: t`Could not share with team`,
        isClosable: true,
        duration: 10000,
        position: "bottom",
        status: "error",
      });
    },
  });

  const unshareProjectMutation = useMutation(unshareProjectWithTeamMembers, {
    onSuccess: () => {
      queryClient.invalidateQueries(["project", projectId]);
      toast({
        title: "Project unshared",
        description: t`Successfully unshared with team`,
        position: "bottom",
        status: "success",
        isClosable: true,
      });
    },
    onError: (err) => {
      console.error(err);
      toast({
        title: "Failed to unshare project",
        description: t`Could not unshare with team`,
        isClosable: true,
        duration: 10000,
        position: "bottom",
        status: "error",
      });
    },
  });

  const isTeamUser = (id: string) => {
    return Boolean(teamData?.some((user) => user.userId === id));
  };

  const sharedProject: ExtendedSharedProject = {
    owners:
      project?.shareProject.owners
        .map((owner) => {
          return { ...owner, isTeam: true };
        })
        .sort((a, b) => a.email.localeCompare(b.email)) || [],

    users:
      project?.shareProject.users
        .map((user) => {
          return { ...user, isTeam: isTeamUser(user.userId) };
        })
        .sort((a, b) => a.email.localeCompare(b.email)) || [],
  };

  const teamMemberEmails = teamData?.map((u) => u.email);

  const isSharedWithTeam = useMemo(() => {
    return teamWitoutOwner.every(
      (user) =>
        project?.shareProject?.users.some(
          (u: SharedProjectUser) => u.email === user.email
        ) ||
        project?.shareProject?.owners.some(
          (u: SharedProjectUser) => u.email === user.email
        )
    );
  }, [teamWitoutOwner, sharedProject, project]);

  const isMaintainer = useMemo(() => {
    return project?.shareProject?.users.find(
      (user) =>
        user.email === userStatusData?.email && user.role === Role.maintainer
    );
  }, [project?.shareProject?.users, userStatusData]);

  const handleChangeSwitch = (option: string) => {
    const role: Role = option as Role;

    const payload = {
      projectId,
      role,
    };
    if (!isSharedWithTeam) {
      shareProjectMutation.mutate(payload);
    } else {
      unshareProjectMutation.mutate();
    }
  };

  if (isTeamLoading || isLoading) {
    return (
      <Box width="100%" py={8}>
        <NotIdealState spacing="4" title={t`Fetching data...`}>
          <CircularProgress capIsRound isIndeterminate />
        </NotIdealState>
      </Box>
    );
  }

  if (isError || userIsError) {
    return (
      <Box width="100%" py={8}>
        <NotIdealState
          icon={FiAlertTriangle}
          spacing="4"
          iconProps={{ color: "red.600" }}
          title={<Trans>Error</Trans>}
          description={<Trans>Could not fetch the data</Trans>}
        />
      </Box>
    );
  }

  return (
    <>
      {team && !isMaintainer && (
        <ShareWithTeam
          isSharedWithTeam={isSharedWithTeam}
          handleChangeSwitch={handleChangeSwitch}
          isLoading={
            shareProjectMutation.isLoading || unshareProjectMutation.isLoading
          }
        />
      )}
      {/* <CraeteTeam billingType={userStatusData?.billingType} /> */}
      <SharedUsersTable
        sharedProject={sharedProject}
        isMaintainer={isMaintainer}
        currentUserEmail={currentUserEmail}
      />
      <ShareUserExternaly
        projectId={projectId}
        teamMemberEmails={teamMemberEmails || []}
      />
    </>
  );
};
