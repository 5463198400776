import { useMemo } from "react";

import { Box, Flex, Td, Text, Tr } from "@chakra-ui/react";

import { BiCheckCircle } from "@react-icons/all-files/bi/BiCheckCircle";
import { RiCloseCircleLine } from "@react-icons/all-files/ri/RiCloseCircleLine";

import { ExtendedSharedProjectUser, Role, SharedProjectUser } from "types";

import { UnshareUser } from "../../unshare";
import { RoleSelect } from "../role-select";

type Props = {
  user: ExtendedSharedProjectUser;
  isProjectOwner?: boolean;
  isOwner?: boolean;
  ownersCount?: number;
  isMaintainer?: SharedProjectUser;
  currentUserEmail?: string | undefined;
};

const UserRow: React.FC<Props> = ({
  user,
  isOwner,
  isMaintainer,
  ownersCount,
  currentUserEmail,
}) => {
  const isHighToManitainer =
    isMaintainer && (user.role === Role.owner || user.role === Role.maintainer)
      ? true
      : false;

  const isAccessCurrentOwner = currentUserEmail
    ? user.email === currentUserEmail
    : false;

  const isShowUnshareButton = useMemo(() => {
    if (isAccessCurrentOwner) {
      return false;
    }

    if (isMaintainer) {
      return user.role === Role.contributor || user.role === Role.readOnly;
    }

    if (isOwner && user.role !== Role.owner) {
      return true;
    }

    if (
      isOwner &&
      user.role === Role.owner &&
      ownersCount !== undefined &&
      ownersCount >= 1
    ) {
      return true;
    } else {
      return false;
    }
  }, [isAccessCurrentOwner, isMaintainer, isOwner, ownersCount]);

  const options = useMemo(() => {
    if (isMaintainer && user.userId === isMaintainer.userId) {
      return [Role.maintainer, Role.contributor, Role.readOnly];
    }

    if (!isHighToManitainer && isMaintainer) {
      return [Role.contributor, Role.readOnly];
    }

    return [Role.owner, Role.maintainer, Role.contributor, Role.readOnly];
  }, [isMaintainer, user, isHighToManitainer]);

  return (
    <Tr
      key={user.userId}
      _hover={{
        bgColor: "gray.100",
      }}
    >
      <Td textAlign="left" textOverflow="ellipsis">
        <Text>{user.email}</Text>
      </Td>

      <Td textAlign="left">
        <Flex justifyContent={"center"}>
          {
            <Box
              as={user.isTeam ? BiCheckCircle : RiCloseCircleLine}
              w={4}
              h={4}
              color={user.isTeam ? "green.500" : "red.500"}
            />
          }
        </Flex>
      </Td>

      <Td
        textAlign="left"
        cursor={isAccessCurrentOwner ? "no-drop" : "pointer"}
      >
        {isAccessCurrentOwner ? (
          <Box w="fit-content">
            <Text fontSize={"md"}> owner</Text>
          </Box>
        ) : (
          <RoleSelect
            user={user}
            isTeamUser={user.isTeam}
            isHighToManitainer={isHighToManitainer}
            options={options}
            isAccessCurrentOwner={isAccessCurrentOwner}
          />
        )}
      </Td>

      <Td textAlign="left" p={0} mr={4}>
        <Flex alignItems="left">
          {isShowUnshareButton && (
            <UnshareUser
              userId={user.userId}
              projectId={user.projectId}
              email={user.email}
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
};

export default UserRow;
